<template>
  <BarGraphic :options="chartOptions" :id="id" :data="chartData" />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BarChartBySuppliers",
  components: {
    BarGraphic: () => import("@/components/ui/graphs/BarGraphic"),
  },
  computed: {
    chartData() {
      return {
        labels: this.$store.state.stats.lists.bySuppliers.map(
          (c) => c.supplier_name
        ),
        datasets: [
          {
            backgroundColor: this.$store.state.stats.lists.bySuppliers.map(
              (c) => this.randomColor()()
            ),
            data: this.$store.state.stats.lists.bySuppliers.map(
              (c) => c.cantidad
            ),
          },
        ],
      };
    },
  },
  mounted() {},
  methods: {
    ...mapGetters("app", ["randomColor"]),
  },
  data() {
    return {
      id: "suppliersGraph",
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        maxBarThickness: 20,
        minBarThickness: 10,
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
};
</script>
<style></style>
